<template>
  <main v-if="check_auth">
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class=" flex justify-between items-center">
        <h1 class="text-2xl font-bold capitalize">
          {{ provider }} Bulk Transfer
        </h1>
        <div>
          <div class="flex items-center gap-4">
            <p class=" text-sm font-medium uppercase">Kuda</p>
            <label class="switch">
              <input
                v-model="provider"
                true-value="vfd"
                false-value="kuda"
                type="checkbox"
              />
              <span class="slider round"></span>
            </label>
            <p class=" text-sm font-medium uppercase">VFD</p>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center">
        <div class="mt-10 font-semibold">
          Account Balance: {{ format(accountBalance) }}
          <button
            class="inline-block drop-shadow-2xl border-2
            bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2 ml-5"
            @click="makeBalanceEnquiryRequest"
          >
            Refresh
          </button>
        </div>

        <div>
          <button
            class="inline-block drop-shadow-2xl border-2
            bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2 ml-5"
            @click="$router.push({ name: 'BulkSweepNewTransfer' })"
          >
            Make New Transfer
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-50 mt-10">
        <div class="block">
          <label class="text-xs block font-bold">Date (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2  mt-3 px-5 rounded-md mr-2 w-40"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>
        <div class="block">
          <label class="text-xs block font-bold">Date (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2  mt-3 px-5 rounded-md mr-2 w-40"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
            :max="new Date().toISOString().split('T')[0]"
          />
        </div>
        <div class="block">
          <button
            style="margin-top: 28px;"
            class="inline-block drop-shadow-2xl border-2
            bg-blue-900 text-white py-2  px-3 rounded-md mr-2"
            @click="bulkSweepHistoryRequest"
          >
            Search
          </button>
        </div>
      </div>
      <div class="mt-14 w-full" style="height: 600px; overflow: auto; ">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="9"
              style="background: #DEE6F2;"
            >
              Bulk Sweep Transactions
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2"
            >
              Date
            </th>
            <th
              class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2"
            >
              Amount Submitted
            </th>
            <th
              class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2"
            >
              Transfer Amount
            </th>
            <th
              class="bg-blue-100 text-xs whitespace-nowrap text-left px-8 py-2"
            >
              Destination Bank
            </th>
            <th
              class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
            >
              Destination Account No
            </th>
            <th
              class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
            >
              Account Name
            </th>
            <th
              class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
            >
              Initiator Name
            </th>
            <th
              class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
            >
              Status
            </th>
            <th
              class="bg-blue-100 text-xs border whitespace-nowrap text-left px-8 py-2"
            >
              Action
            </th>
          </tr>
          <tr class="text-xs" v-for="(account, i) in history" :key="i">
            <td>
              {{
                moment.utc(account.CreatedAt).format('DD MMM YYYY - HH:mm ') ||
                  'N/A'
              }}
            </td>
            <td>{{ format(account.AmountSubmitted) || 'N/A' }}</td>

            <td>{{ format(account.AmountTransferred) || 'N/A' }}</td>
            <td>
              {{ account.BankCode || 'N/A' }}
            </td>
            <td>
              {{ account.DestinationAccountNumber || 'N/A' }}
            </td>

            <td>
              {{ account.DestinationAccountName || 'N/A' }}
            </td>
            <td>
              {{ account.SubmittedByName || 'N/A' }}
            </td>
            <td>
              {{ getStatus(account.Status) }}
            </td>
            <td>
              <button
                @click="approveTransfer(account.BulkId)"
                class=" border-2 px-3 py-2 border-blue-400 text-white bg-blue-400 rounded-sm w-40"
                v-if="account.Status === 'New transaction'"
              >
                Approve
              </button>
              <button
                @click="
                  $router.push(
                    `/sweep-request/${account.BulkId}/${account.BankCode}/${account.DestinationAccountNumber}/${account.DestinationAccountName}`
                  )
                "
                class=" border-2 px-3 py-2 border-blue-400 text-blue-500 rounded-sm w-40"
                v-else
              >
                Transaction Details
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <pagination
          v-model="currentPage"
          :records="totalPages"
          :per-page="100"
        />
      </div>
    </div>

    <!-- <div
      v-if="newTransfer"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >

    </div> -->
  </main>
  <main v-else class="w-1/4 mx-auto">
    <h5 class="text-3xl font-bold text-blue-800 text-center">
      Sorry, you're not authorized to view this page.
    </h5>
  </main>
</template>

<script>
import kudaUsers from '../config/kudaUsers'
import { GET_USER_BUSINESS_DATA } from '../browser-db-config/localStorage'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Pagination from 'vue-pagination-2'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      moment,
      isLoading: false,
      currentPage: 1,
      isDisabled: false,
      dateData: null,
      balance: 0,
      history: [],
      banks: [],
      totalPages: 0,
      newTransfer: false,
      enquiry: {
        bank: null,
        account: null,
      },
      nameStatus: {},
      amount: 0,
      provider: 'kuda',
    }
  },
  computed: {
    accountBalance() {
      return this.balance || 0
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email
      return kudaUsers.includes(userEmail)
    },
  },

  watch: {
    async provider(val) {
      if (val) {
        await this.initialize()
      }
    },
  },

  async created() {
    this.initialize()
  },
  methods: {
    format(balance) {
      const formatter = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      if (typeof balance === 'string') {
        return balance
      }
      if (balance) {
        return formatter.format(balance)
      }
    },

    async initialize() {
      const d = new Date()
      this.dateData = {
        startdatetime: new Date(d.setDate(d.getDate() - 1))
          .toJSON()
          .split('T')[0],
        enddatetime: new Date().toJSON().split('T')[0],
        startTime: '00:00',
        endTime: this.moment(d).format('HH:mm'),
        enddatetime: new Date().toJSON().split('T')[0],
      }

      this.provider === 'kuda' ? await this.getBanks() : this.getVFDBanks()
      await this.makeBalanceEnquiryRequest()
      this.provider === 'kuda'
        ? await this.bulkSweepHistoryRequest()
        : this.vfdBulkSweepHistory()
    },

    async makeBalanceEnquiryRequest() {
      this.isLoading = true
      try {
        const balanceEnquiry = await this.$store.dispatch(
          'BALANCE_ENQUIRY',
          this.provider
        )
        if (
          balanceEnquiry &&
          balanceEnquiry.data &&
          balanceEnquiry.data.AvailableBalance
        ) {
          this.balance = balanceEnquiry?.data.AvailableBalance
        } else if (balanceEnquiry.error) {
          this.balance = balanceEnquiry.error
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async bulkSweepHistoryRequest() {
      let payload = {
        page: +this.currentPage,
        startdate: this.dateData.startdatetime,
        enddate: this.dateData.enddatetime,
        provider: this.provider,
      }
      this.isLoading = true
      try {
        const bulkSweepHistory = await this.$store.dispatch(
          'BULK_SWEEP_HISTORY',
          payload
        )
        this.history = bulkSweepHistory?.data.BulkSweep
        this.totalPages = bulkSweepHistory?.data.TotalRecords
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async vfdBulkSweepHistory() {
      let payload = {
        page: +this.currentPage,
        startdate: this.dateData.startdatetime,
        enddate: this.dateData.enddatetime,
        provider: this.provider,
      }
      this.isLoading = true
      try {
        const vfdHistory = await this.$store.dispatch(
          'VFD_BULK_SWEEP_HISTORY',
          payload
        )
        console.log(vfdHistory, 'vfdHistory')
        // this.history = bulkSweepHistory?.data.BulkSweep
        // this.totalPages = bulkSweepHistory?.data.TotalRecords
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async getBanks() {
      this.isLoading = true
      try {
        const banks = await this.$store.dispatch('GET_BANKS')
        this.banks = banks.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async getVFDBanks() {
      this.isLoading = true
      try {
        const banks = await this.$store.dispatch('GET_VFD_BANKS')
        console.log(banks, 'llll')
        // this.banks = banks.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    getBankByCode(val) {
      const bank = this.banks.find((bank) => bank.provider_code === val)
      return (bank && bank.name) || 'N/A'
    },

    async nameEnquiry() {
      let payload = this.enquiry
      try {
        const nameEnquiry = await this.$store.dispatch('NAME_ENQUIRY', payload)
        this.nameStatus = nameEnquiry.data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async approveTransfer(id) {
      let payload = {
        bulkId: id,
      }
      try {
        const approveTransfer = await this.$store.dispatch(
          'INITIATE_BULK_SWEEP',
          payload
        )

        await this.bulkSweepHistoryRequest()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    closeModal() {
      this.newTransfer = false
      this.enquiry = {
        bank: null,
        account: null,
      }
      this.nameStatus = {}
      this.amount = 0
    },

    getStatus(val) {
      switch (val) {
        case 'New transaction':
          return 'New'
        case 'Transaction in progress':
          return 'In Progress'
        case 'Transaction completed':
          return 'Completed'
        default:
          return val
      }
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #402669;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e4192d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: rgb(1, 103, 90);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<style>
/* Chrome, Safari, Edge, Opera */

.VuePagination__pagination {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 1px;
}
.VuePagination__pagination li {
  border: 1px solid #ddd !important;
  color: #686de0;
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.VuePagination__pagination .active {
  background-color: #686de0;
  color: #fff;
}
.VuePagination__count {
  text-align: center;
  margin-top: 5px;
}
</style>
